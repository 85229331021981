import React from 'react';
//import { Input } from './ui/input';
import { Button } from './ui/button';
import { Users, Video, Globe, CreditCard, Twitter, Linkedin, Zap, BookOpen, Mail, GitBranch, BarChart3 } from 'lucide-react';

const LandingPage = () => {
  //const [email, setEmail] = useState('');
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  //const [submitted, setSubmitted] = useState(false);

  

  const renderFeatures = () => {
    const features = [
      {
        icon: <Video className="w-8 h-8 text-purple-500" />,
        title: "Coaching Sessions",
        description: "Run intimate 1:1 sessions or scale with group coaching — all with the same seamless experience."
      },
      {
        icon: <Globe className="w-8 h-8 text-purple-500" />,
        title: "Content Hub",
        description: "Share videos, courses, and posts that keep clients engaged. No more juggling multiple platforms."
      },
      {
        icon: <Users className="w-8 h-8 text-purple-500" />,
        title: "Community",
        description: "Give clients a place to connect, share wins, and support each other between sessions."
      },
      {
        icon: <CreditCard className="w-8 h-8 text-purple-500" />,
        title: "Payments",
        description: "Get paid on time, every time with automated Stripe payments. No payment chasing needed."
      },
      {
        icon: <BarChart3 className="w-8 h-8 text-purple-500" />,
        title: "Business Intelligence",
        description: "Track revenue, monitor client engagement, and spot churn risks before they happen. Make decisions backed by data."
      },
      {
        icon: <Zap className="w-8 h-8 text-purple-500" />,
        title: "AI Session Highlights",
        tag: "coming soon",
        description: "Every session automatically transcribed and analyzed. Get key insights, action items, and progress tracking without lifting a finger."
      },
      {
        icon: <BookOpen className="w-8 h-8 text-purple-500" />,
        title: "Online Courses",
        tag: "coming soon",
        description: "Create and sell beautiful courses with our drag-and-drop builder. Turn your expertise into passive income."
      },
      {
        icon: <Mail className="w-8 h-8 text-purple-500" />,
        title: "Newsletters",
        tag: "coming soon",
        description: "Keep your audience engaged with automated newsletters. Build loyalty and drive sales on autopilot."
      },
      {
        icon: <GitBranch className="w-8 h-8 text-purple-500" />,
        title: "Marketing Automation",
        tag: "coming soon",
        description: "Set up powerful automation flows that nurture leads and convert them into high-ticket clients."
      }
    ];

    return features.map((feature, index) => (
      <div 
        key={index} 
        className="p-6 rounded-2xl bg-gradient-to-b from-white/5 to-transparent backdrop-blur-sm hover:from-white/10 transition-all duration-300"
      >
        <div className="mb-4 flex items-center justify-between">
          <div className="flex items-center gap-3">
            {feature.icon}
          </div>
          {feature.tag && (
            <span className="text-xs text-purple-400/60 font-medium">
              {feature.tag}
            </span>
          )}
        </div>
        <h3 className="text-xl font-semibold mb-2 text-purple-400">
          {feature.title}
        </h3>
        <p className="text-gray-400">
          {feature.description}
        </p>
      </div>
    ));
  };

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50 backdrop-blur-lg bg-black/50">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              {/* Logo */}
              <div className="font-medium tracking-wide text-xl text-white relative">
                Atom<span className="relative">Unity
                  <span className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-purple-400 to-transparent"></span>
                </span>
              </div>
            </div>
            <Button 
              className="bg-white/10 hover:bg-white/20 text-white"
              onClick={() => document.querySelector('[data-formkit-toggle="ffcd48e8f6"]').click()}
            >
              Join Waitlist
            </Button>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-purple-900 via-black to-black opacity-90" />
        
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-20">
          <div className="text-center max-w-4xl mx-auto">
            <h1 className="text-5xl sm:text-6xl font-bold mb-6 bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">
              Your Coaching Empire, On Autopilot
            </h1>
            <p className="text-xl sm:text-2xl text-gray-300 mb-12">
              Stop juggling tools. One premium platform for everything: content, community, coaching, and payments.
            </p>
            
            {/* Hero CTA */}
            <div className="max-w-md mx-auto">
              <Button 
                className="w-full bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 text-white px-8 py-6 text-lg"
                data-formkit-toggle="ffcd48e8f6"
              >
                Get Early Access
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center mb-16">
          <h2 className="text-3xl sm:text-4xl font-bold mb-4">
            Everything You Need In One Place
          </h2>
          <p className="text-gray-400 text-lg">
            Every critical tool your coaching business needs to thrive
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-8">
          {/* Current Features */}
          {renderFeatures().slice(0, 5)}
        </div>

        <div className="mt-12 pt-12 border-t border-white/5">
          <div className="text-center mb-12">
            <h3 className="text-2xl font-semibold mb-2 text-purple-400">Coming Soon</h3>
            <p className="text-gray-400">More powerful features on the way</p>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Coming Soon Features */}
            {renderFeatures().slice(5)}
          </div>
        </div>
      </div>

      {/* Final CTA Section */}
      <div className="relative py-24">
        <div className="absolute inset-0 bg-gradient-to-t from-purple-900/20 to-transparent" />
        <div className="relative max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6">
            Be First In Line When We Launch
          </h2>
          <p className="text-xl text-gray-300 mb-8">
            Early access members get lifetime priority support and special launch pricing.
          </p>
          <Button 
            onClick={() => document.querySelector('[data-formkit-toggle="ffcd48e8f6"]').click()}
            className="bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 text-white px-8 py-6 text-lg"
          >
            Get Early Access
          </Button>
        </div>
      </div>
      
      {/* Footer */}
      <footer className="bg-black/50 backdrop-blur-lg border-t border-white/10">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            {/* Logo */}
            <div className="font-medium tracking-wide text-xl text-white relative">
              Atom<span className="relative">Unity
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-purple-400 to-transparent"></span>
              </span>
            </div>
            
            {/* Social Links */}
            <div className="flex space-x-4">
              <a href="twitter.com" className="text-gray-400 hover:text-white transition-colors">
                <Twitter className="w-6 h-6" />
              </a>
              <a href="linkedin.com" className="text-gray-400 hover:text-white transition-colors">
                <Linkedin className="w-6 h-6" />
              </a>
            </div>
          </div>

          <div className="mt-8 text-center text-gray-400 text-sm">
            Built by the awesome folks that bring you <a href="https://atomchat.com" className="text-gray-400 hover:text-white transition-colors">AtomChat</a> & <a href="https://cometchat.com" className="text-gray-400 hover:text-white transition-colors">CometChat</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
